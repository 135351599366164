<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :lg="12" :xs="21">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>

        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            :style="{ height: '63px', paddingLeft: 0 }"
            :title="materialData.name"
            @back="backToMaterialsPage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>

      <a-col :lg="12" :xs="3" align="right">
        
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header --> 

  <!-- Layout Content -->
  <a-layout-content class="layout-content">
    <div
      v-if="!loading"
      class="app-material-resources-page"
    >
      <a-card class="page-card" :bordered="false">
        <div class="card-header">
          <a-row>
            <a-col :span="12">
              回家作業
            </a-col>
            <a-col :span="12" align="right">
              <a-button
                type="primary"
                @click="homeworkModal.visible=true"
              >新增</a-button>
            </a-col>
          </a-row>
        </div>
        <div class="card-body">
          <a-table
            :dataSource="materialData.resources.filter(item => item.type === 'hw')"
            :columns="hwColumns"
            :scroll="{ x: 'max-content' }"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.key === 'name'">
                {{ record.data.name }}
              </template>

              <template v-if="column.key === 'description'">
                {{ record.data.description }}
              </template>

              <template v-if="column.key === 'created_datetime'">
                {{ DateUtil.formatDatetime(record.created_datetime) }}
              </template>

              <template v-if="column.key === 'actions'">
                <a-popconfirm
                  title="刪除作業資源"
                  ok-text="確定"
                  cancel-text="取消"
                  danger
                  @confirm="deleteOneResource(record.material_id, record.id)"
                  @cancel="message.info('取消刪除作業資源動作')"
                >
                  <a-button>刪除</a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </div>
      </a-card>

      <a-card class="page-card" :bordered="false">
        <div class="card-header">
          <a-row>
            <a-col :span="12">
              影片資源
            </a-col>
            <a-col :span="12" align="right">
              <a-button
                type="primary"
                @click="videoModal.visible=true"
              >新增</a-button>
            </a-col>
          </a-row>
        </div>
        <div class="card-body">
          <a-table
            :dataSource="materialData.resources.filter(item => item.type === 'video')"
            :columns="videoColumns"
            :scroll="{ x: 'max-content' }"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.key === 'name'">
                {{ record.data.name }}
              </template>

              <template v-if="column.key === 'description'">
                {{ record.data.description }}
              </template>

              <template v-if="column.key === 'url'">
                {{ record.data.url }}
              </template>

              <template v-if="column.key === 'created_datetime'">
                {{ DateUtil.formatDatetime(record.created_datetime) }}
              </template>

              <template v-if="column.key === 'actions'">
                <a-popconfirm
                  title="刪除影片資源"
                  ok-text="確定"
                  cancel-text="取消"
                  danger
                  @confirm="deleteOneResource(record.material_id, record.id)"
                  @cancel="message.info('取消刪除影片資源動作')"
                >
                  <a-button>刪除</a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
  </a-layout-content>
  <!-- Layout Content -->

  <HomeworkModal
    :visible="homeworkModal.visible"
    @confirm="handleHomeworkModalConfirm"
    @cancel="homeworkModal.visible=false"
  />

  <VideoModal
    :visible="videoModal.visible"
    @confirm="handleVideoModalConfirm"
    @cancel="videoModal.visible=false"
  />
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { message } from 'ant-design-vue'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'
import HomeworkModal from './components/HomeworkModal.vue'
import VideoModal from './components/VideoModal.vue'

/* Table */
const hwColumns = [{
  title: '名稱',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '描述',
  dataIndex: 'description',
  key: 'description'
}, {
  title: '建立時間',
  dataIndex: 'created_datetime',
  key: 'created_datetime'
}, {
  title: '操作',
  dataIndex: 'actions',
  key: 'actions'
}]

const videoColumns = [{
  title: '名稱',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '描述',
  dataIndex: 'description',
  key: 'description'
}, {
  title: '網址',
  dataIndex: 'url',
  key: 'url'
}, {
  title: '建立時間',
  dataIndex: 'created_datetime',
  key: 'created_datetime'
}, {
  title: '操作',
  dataIndex: 'actions',
  key: 'actions'
}]

export default defineComponent({
  components: {
    HomeworkModal,
    VideoModal
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();

    /* Store */
    const appStore = useAppStore();

    /* Breadcrumbs */
    const breadcrumbRoutes = ref([
      {
        path: '/app/materials',
        breadcrumbName: '資源庫'
      }
    ]);

    /* Loading */
    const loading = ref(true);

    /* Data */
    const materialData = ref({});

    /* Api */
    const getMaterialData = (async() => {
      const response = await api.v1.material.info(route.params.material_id);
      materialData.value = response.data.result;
      loading.value = false;

      console.log(materialData.value)
    });

    /* Mounted */
    onMounted(async () => {
      await getMaterialData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/materials/${ materialData.value.id }`,
        breadcrumbName: materialData.value.name
      });
    });

    return {
      device,

      /* Store */
      appStore,

      /* Breadcrumbs */
      breadcrumbRoutes,

      /* Loading */
      loading,

      /* Data */
      materialData,

      /* Api */
      getMaterialData,

      /* Table */
      hwColumns,
      videoColumns,

      /* Uitls */
      DateUtil,

      /* Antd */
      message
    }
  },
  data() {
    return {
      /* Modal */
      homeworkModal: {
        visible: false
      },
      videoModal: {
        visible: false
      }
    }
  },
  methods: {
    /* Router */
    backToMaterialsPage() {
      this.$router.push({ name: 'AppMaterials' });
    },

    /* Modal */
    handleHomeworkModalConfirm() {
      this.getMaterialData();
      this.homeworkModal.visible = false;
    },
    handleVideoModalConfirm() {
      this.getMaterialData();
      this.videoModal.visible = false;
    },

    /* Api */
    async deleteOneResource(material_id, resource_id) {
      try {
        await api.v1.material.deleteOneResource(material_id, resource_id);
        this.message.success('刪除資源成功');
        this.getMaterialData();
      } catch (error) {
        console.log(error);
        this.message.error('刪除資源發生錯誤，請稍後再試');
      }
    }
  }
})
</script>

<style lang="scss">
.app-material-resources-page {
  .page-card {
    margin-bottom: 20px;

    .card-header {
      color: #212121;
      font-size: 1.45rem;
      font-weight: 450;
      line-height: 3rem;
      letter-spacing: 0.025rem;
      margin-bottom: 12px;
    }
  }
}
</style>