<template>
  <a-modal
    :visible="visible"
    title="新增作業資源"
    okText="確認"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :model="formState"
      layout="vertical"
      autocomplete="off"
    >
      <a-form-item
        label="作業名稱"
      >
        <a-input v-model:value="formState.data.name" size="large" />
      </a-form-item>

      <a-form-item
        label="作業描述"
      >
        <a-input v-model:value="formState.data.description" size="large" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import api from '@/apis'
import CopyUtil from '@/utils/CopyUtil'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const route = useRoute();

    const initialState = {
      material_id: route.params.material_id,
      type: 'hw',
      data: {
        name: '',
        description: '',  
      }
    }
    const formState = ref(CopyUtil.iterateObjectDeepClone(initialState));
    function resetForm() {
      formState.value = CopyUtil.iterateObjectDeepClone(initialState);
    }

    return {
      formState,
      resetForm
    }
  },
  methods: {
    async handleOk() {
      // check required
      if (!this.formState.data.name) {
        message.error('請輸入作業名稱');
        return
      } else if (!this.formState.data.description) {
        message.error('請輸入作業描述');
        return
      }
      
      // insert resource
      try {
        await api.v1.material.insertOneResource(
          this.$route.params.material_id,
          this.formState
        );
        message.success('新增作業資源成功');
      } catch (error) {
        message.error('新增回家資源發生錯誤，請稍後再試');
        console.log(error);
      }

      this.$emit('confirm');
      this.resetForm();

      console.log(this.formState)
    },
    async handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
})
</script>