<template>
  <a-modal
    :visible="visible"
    title="新增影片資源"
    okText="確認"
    cancelText="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :model="formState.data"
      layout="vertical"
      autocomplete="off"
    >
      <a-form-item
        label="影片名稱"
        name="name"
        :rules="[{ required: true, message: '請輸入影片名稱' }]"
      >
        <a-input v-model:value="formState.data.name" size="large" />
      </a-form-item>

      <a-form-item
        label="影片描述"
        name="description"
        :rules="[{ required: true, message: '請輸入影片描述' }]"
      >
        <a-input v-model:value="formState.data.description" size="large" />
      </a-form-item>

      <a-form-item
        label="影片連結 (Youtube)"
        name="url"
        :rules="[{ required: true, message: '請輸入影片連結' }]"
      >
        <a-input v-model:value="formState.data.url" size="large" />
      </a-form-item>

      <a-form-item v-if="isAvailableUrl">
        <div class="preview">
          <iframe
            width="100%" height="250"
            :src="`https://www.youtube.com/embed/${youtubeIdParser(formState.data.url)}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import api from '@/apis'
import CopyUtil from '@/utils/CopyUtil'

export default defineComponent({
  props: ['visible'],
  components: {},
  setup() {
    const route = useRoute();

    const initialState = {
      material_id: route.params.material_id,
      type: 'video',
      data: {
        name: '',
        description: '',
        url: ''
      }
    }
    const formState = ref(CopyUtil.iterateObjectDeepClone(initialState));
    function resetForm() {
      formState.value = CopyUtil.iterateObjectDeepClone(initialState);
    }

    function youtubeIdParser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
    }
    
    return {
      /* FormState */
      formState,
      resetForm,

      youtubeIdParser
    }
  },
  computed: {
    isAvailableUrl() {
      if (this.formState.data.url.includes('https://') && this.formState.data.url.includes('youtube')) return true
      return false
    }
  },
  methods: {
    async handleOk() {
      // check required
      if (!this.formState.data.name) {
        message.error('請輸入影片名稱');
        return
      } else if (!this.formState.data.description) {
        message.error('請輸入影片描述');
        return
      } else if (!this.formState.data.url) {
        message.error('請輸入影片網址');
        return
      }

      // insert resource
      try {
        await api.v1.material.insertOneResource(
          this.$route.params.material_id,
          this.formState
        );
        message.success('新增影片資源成功');
      } catch (error) {
        message.error('新增影片資源發生錯誤，請稍後再試');
        console.log(error);
      }

      this.$emit('confirm');
      this.resetForm();
    },
    async handleCancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
})
</script>